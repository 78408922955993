@import '../_variables';

.search-form{
    position: relative;
    z-index: 10;
    
    .form-control{
        height: 60px;
        padding-right: 175px;
        padding-left: 62px;
        border: none;
        border-radius: 50rem;
        background-color: @color-white;
        background-repeat: no-repeat;
        background-position: 26px center;
        background-size: auto;
        
        &.tt-input{
            background-image: url(img/icon-planet.svg) !important;
            
            &.loading{
                background: transparent url(img/loading.gif) no-repeat 26px center border-box !important;
            }
        }
    }
    
    .btn{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;
        height: 100%;
        border-radius: 50rem;
    }
}

.live-search{
    position: relative;
    
    .tt-menu{
        margin-top: 2px;
        
        &.tt-open{
            padding: 22px;
            border-radius: 30px;
            background-color: #ffffff;
            font-family: BwSurco;
            font-size: 16px;
        }
        
        .tt-suggestion{
            padding: 10px 15px;
            border-radius: 10px;
            cursor: pointer;
            transition: @transition;
            
            &:last-child{
                margin-bottom: 0;
            }
            
            &:hover{
                background-color: @color-link-hover;
                cursor: pointer;
            }
            
            strong{
                font-weight: bolder;
            }
        }
    }
    
    & + .invalid-feedback{
        top: 0;
        right: 0;
        left: 0;
        max-width: none;
        padding: 0 36px;
        text-align: center;
    }
}

@media (min-width: 992px) and (max-width: 1200px){

}

@media (min-width: 768px) and (max-width: 991px){
    .search-form{
        .form-control{
            padding-left: 56px;
            background-position: 20px center;
        }
    }
}

@media (min-width: 576px) and (max-width: 767px){
    .search-form{
        .form-control{
            padding-left: 56px;
            background-position: 20px center;
        }
    }
}

@media (max-width: 575px){
    .search-form{
        .form-control{
            padding-right: 70px;
            background-image: none;
        }
        
        .btn{
            width: 60px;
            height: 60px;
            padding: 0;
            border-radius: 50%;
            background-image: url(img/icon-loupe.svg);
            background-repeat: no-repeat;
            background-position: center center;
            font-size: 0;
            
            span{
                display: none;
            }
        }
    }
}