@import '../_variables';

#modal-search{
    .modal-dialog{
        margin-top: 17.3vh;
    }
    
    .modal-content{
        border: none;
        background-color: transparent;
    }
    
    .close{
        position: absolute;
        top: -75px;
        right: -75px;
        z-index: 5;
        width: 36px;
        height: 36px;
        outline: none;
        background-image: url(img/icon-modal-close.svg);
        background-repeat: no-repeat;
        
        &:not(:disabled):not(.disabled):focus, &:not(:disabled):not(.disabled):hover{
            opacity: 1;
        }
    }
    
    h3{
        margin-bottom: 30px;
        color: @color-white;
        font-family: BwSurco-Black;
        font-size: 24px;
    }
}

.modal-backdrop{
    &.show{
        opacity: .75;
    }
}

#modal-guide-car{
    .modal-content{
        .owl-stage-outer{
            height: auto;
        }
    }
}

.modal{
    .modal-header{
        padding: 0;
        border: none;
        border-radius: 0;
        
        h5{
            margin-top: 2px;
            margin-bottom: 18px;
            font-family: BwSurco;
            font-size: 22px;
            font-weight: bold;
        }
        
        .close{
            position: absolute;
            top: 0;
            right: 75px;
            z-index: 1;
            width: 55px;
            height: 55px;
            margin: 0;
            border-radius: 0 0 27px 27px;
            outline: none;
            opacity: 1 !important;
            background-color: #2c2c2c;
            background-image: url(img/icon-close.svg);
            background-repeat: no-repeat;
            background-position: center center;
        }
        
        &.small-right{
            .close{
                right: 15px;
            }
        }
    }
    
    &.right{
        &.fade{
            .modal-dialog{
                display: flex;
                transform: translate(100%, 0);
            }
            
            &.show .modal-dialog{
                transform: none;
            }
        }
        
        .modal-dialog{
            width: 490px;
            max-width: 100%;
            min-height: 100%;
            margin: 0 0 0 auto;
            background-color: @color-white;
            
            &.bg-wave{
                .footer-modal{
                    border-color: @color-white;
                    background-image: url(img/ctor-min.png);
                    background-position: center top;
                    background-size: 100% auto;
                }
            }
            
            &.bg-circle{
                background-image: url(img/bg-two-circle.png);
                background-repeat: no-repeat;
                background-position: right bottom;
            }
        }
        
        .modal-content{
            min-height: 100%;
            padding: 60px;
            border: none;
            border-radius: 0;
            background-color: #ffffff;
            font-family: BwSurco-Book;
            font-size: 14px;
        }
        
        .modal-body{
            padding: 0;
            
            p{
                margin-bottom: 5px;
                line-height: 20px;
            }
        }
    }
    
    &.with-image{
        .modal-content{
            border: none;
            border-radius: 0;
            background-color: transparent;
        }
        
        .modal-body{
            overflow: hidden;
            padding: 0;
        }
        
        .close{
            position: absolute;
            top: -35px;
            right: 0;
            width: 20px;
            height: 20px;
            outline: 0;
            opacity: 1 !important;
            background-image: url(img/icon-close.svg);
            background-repeat: no-repeat;
            background-position: 0 0;
        }
    }
    
    &.modal-edit-image{
        .modal-header{
            padding: 1rem;
            
            h5{
                margin-top: 15px;
                margin-bottom: 0;
            }
            
            .close{
                right: 1rem;
            }
        }
        
        .modal-footer{
            display: block;
        }
        
        .btn-group{
            margin-bottom: 15px;
            
            .btn{
                height: auto;
                padding: 0;
            }
        }
        
        .btn-file{
            height: auto;
            padding: .375rem .75rem;
        }
        
        .buttons{
            text-align: center;
            
            .btn{
                height: 45px;
                margin-right: 30px;
                margin-bottom: 15px;
                padding: 10px 30px;
                border-radius: 30px;
                font-family: BwSurco-Medium;
                font-size: 14px;
                
                .fa{
                    margin-right: 10px;
                    font-size: 16px;
                }
            }
        }
    }
    
    &.small-modal{
        padding: 0;
        
        .modal-content{
            border-radius: 10px;
        }
        
        .modal-header{
            .close{
                right: 20px;
            }
        }
        
        .modal-body{
            padding: 165px 85px 60px;
            background-image: url(img/small-modal-image.png);
            background-repeat: no-repeat;
            background-position: center 50px;
            text-shadow: 0 0 black;
            font-family: BwSurco-Book;
            font-size: 15px;
            text-align: center;
            
            h5{
                margin-top: 0;
                font-family: BwSurco;
                font-size: 22px;
                font-weight: bold;
            }
            
            p{
                margin-bottom: 0;
            }
        }
    }
    
    &.full-width{
        .modal-content{
            border: none;
        }
        
        .modal-body{
            max-width: 1140px;
            margin: 0 auto;
            width: 100%;
            padding-bottom: 45px;
        }
        
        .modal-dialog{
            margin-top: 0;
            width: 100%;
            max-width: 100%;
        }
    }
    
    &.informer{
        .modal-body{
            text-align: center;
            margin-top: 35px;
            margin-bottom: 35px;
            padding-left: 40px;
            padding-right: 40px;
        }
        
        .modal-image{
            margin-bottom: 25px;
        }
        
        h3{
            font-size: 22px;
            margin-bottom: 10px;
            margin-top: 0;
            font-family: 'BwSurco';
            font-weight: bold;
        }
        
        .text{
            font-family: BwSurco-Book;
            font-size: 15px;
            line-height: 22px;
        }
        
        .block-button{
            width: 210px;
            margin: 25px auto 0;
            
            .btn{
                display: block;
                width: 100%;
                margin-bottom: 15px;
            }
        }
    }
}

.modal-form{
    margin-top: 45px;
    
    .btn{
        display: block;
        width: 100%;
        
        svg{
            margin-left: 35px;
        }
    }
}

#modal-register-email{
    padding-right: 0 !important;
}

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover{
    opacity: 1;
}

#modal-register-success, #modal-re-pass-success{
    .text-main{
        margin-bottom: 60px;
    }
    
    .btn-primary{
        display: block;
        margin: 0 auto;
    }
}

.modal-guide-review{
    .modal-header{
        display: block;
        
        img{
            margin-bottom: 30px;
        }
        
        h5{
            line-height: 26px;
        }
    }
    
    .modal-content{
        font-size: 15px !important;
        
        a{
            text-decoration: underline;
        }
    }
    
    .block-links{
        width: 200px;
        margin: 25px auto;
        
        .btn{
            display: block;
            width: 100%;
            height: auto;
            margin-bottom: 10px;
            padding-top: 7px;
            padding-bottom: 7px;
            box-shadow: none;
            font-family: BwSurco-Medium;
            font-size: 15px;
            text-decoration: none;
        }
    }
    
    .block-rating{
        display: flex;
        position: relative;
        margin-bottom: 30px;
        align-items: center;
        justify-content: space-between;
        
        .title{
            font-family: BwSurco;
            font-size: 16px;
            font-weight: bold;
        }
        
        label{
            position: static;
        }
        
        .invalid-feedback{
            top: -15px;
        }
        
        .icon-star{
            width: 24px;
            height: 24px;
            
            &.empty{
                background-image: url(img/icon-mess-review-star-empty.svg);
            }
            
            &.filled{
                background-image: url(img/icon-mess-review-star-filled.svg);
            }
        }
        
        .caption{
            width: 20px;
            font-family: BwSurco;
        }
    }
    
    .modal-form{
        .btn{
            font-family: BwSurco-Medium;
            font-size: 16px;
        }
    }
}

@media (min-width: 992px) and (max-width: 1200px){

}

@media (min-width: 768px) and (max-width: 991px){

}

@media (min-width: 576px) and (max-width: 767px){

}

@media (max-width: 575px){
    .modal{
        .modal-content{
            padding-right: 20px;
            padding-left: 20px;
        }
        
        &.informer{
            .modal-body{
                padding-left: 0;
                padding-right: 0;
            }
        }
        
        &.with-image{
            .owl-nav{
                display: none;
            }
        }
    }
    
    .modal.right{
        .modal-content{
            padding-right: 15px;
            padding-left: 15px;
        }
    }
}