@import '../_variables';

.form-group{
  position: relative;
  margin-bottom: 29px;

  label{
    position: absolute;
    top: -15px;
    left: 35px;
    margin: 0;
    padding: 5px 10px;
    background-color: #FFF;
    font-family: BwSurco-Medium;
    font-size: 16px;
    user-select: none;
  }

  .icon{
    position: absolute;
    top: 50%;
    left: 22px;
    margin-top: -11px;

    .a{
      transition: @transition;
      fill: @color-icon-form-input;
    }
  }

  .pass{
    position: absolute;
    top: 50%;
    right: 20px;
    cursor: pointer;

    &.hide{
      margin-top: -10px;
    }

    &.show{
      margin-top: -12px;
    }

    .a{
      transition: @transition;
      fill: #D0D0D0;
    }

    &:hover, &:active{
      .a{
        fill: #2C2C2C;
      }
    }
  }

  .key{
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -11px;

    .a{
      transition: @transition;
      fill: @color-main
    }
  }

  &:active, &:hover{
    .key{
      .a{
        transition: @transition;
        fill: @color-valid
      }
    }
  }

  &.has-error, &.has-success{
    .icon{
      display: none;
    }
  }

  .file-input{
    margin-top: 10px;

    .file-caption{
      width: auto;

      .file-caption-name{
        font-family: BwSurco-Medium;
        font-size: 13px;
      }
    }

    .input-group-append{
      width: auto;

      .btn{
        width: 100%;
        height: 44px;
        padding: 10px 20px;
        background-image: none;
        font-family: BwSurco-Book;
        font-size: 14px;

        span{
          cursor: inherit;
        }
      }
    }

    .form-control{
      height: 40px;
      padding: 8px 15px;
    }

    .file-preview{
      .icon-delete{
        opacity: 1;
      }
    }

    .invalid-feedback{
      position: static;
      width: 100%;
      max-width: none;
      margin: 10px 0;
      padding: 15px;
      background-color: @color-icon-form-input;
      font-size: 16px;
      text-align: left;

      pre{
        overflow: visible;
        margin-top: 10px;
        margin-bottom: 0;
        padding: 0;
        border: none;
        background-color: transparent;
        box-shadow: none;
        font-family: BwSurco-Book;
        white-space: normal;
      }
    }
  }
}

.form-control{
  height: 60px;
  padding: 12px 55px 12px 15px;
  border: 2px solid @color-border-form-input;
  border-radius: 30px;
  font-family: BwSurco-Medium;

  &::placeholder{
    color: #242424;
  }

  &:focus, &:active, &:hover{
    border-color: @color-link-hover;
    outline: 0;
    color: @color-main;
    background-color: inherit;
    box-shadow: 0 0 10px 2px rgba(3, 200, 229, .25);

    + .icon{
      .a{
        fill: @color-link-hover;
      }
    }
  }

  textarea&{
    resize: none;
    border-radius: 20px;
  }

  &.is-invalid{
    border-color: @color-red;
    background-image: url(img/icon-error.svg);
    background-position: calc(100% - 15px) center;

    &:focus, &:active, &:hover{
      box-shadow: none;
    }

    & + .icon{
      display: none;
    }
  }

  &.is-valid{
    border-color: @color-valid;
    background-image: url(img/icon-valid.svg);
    background-position: calc(100% - 15px) center;

    textarea&{
      background-position: calc(100% - 15px) 15px;
    }

    &:focus, &:active, &:hover{
      box-shadow: none;
      border-color: @color-valid !important;
    }

    & + .icon{
      display: none;
    }

    &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active{
      border-color: @color-valid !important;
      background-image: url(img/icon-valid.svg) !important;
    }
  }

  &-plaintext{
    padding: 0;
    font-family: BwSurco-Book;
    font-size: 15px;
    line-height: normal;
  }

  &:disabled, &[readonly]{
    background-color: transparent;
  }

  &.password-input{
    padding-left: 55px;
    padding-right: 55px;
    background-position: 20px center;

    &.is-invalid{
    }
  }

  &.pass-with-icon{
    padding-left: 55px;
  }
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label{
  color: @color-valid;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before{
  border-color: @color-valid;
  background-color: @color-valid;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before{
  border-color: @color-valid;
}

.invalid-feedback{
  display: block;
  position: absolute;
  top: -20px;
  right: 25px;
  left: 25px;
  z-index: 5;
  max-width: 85%;
  color: #EC3106;
  font-family: BwSurco-Book;
  font-size: 13px;
  line-height: 12px;
  text-align: right;
}

input[type=checkbox], input[type=radio]{
  display: none;

  & + label{
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;
    transition: @transition;

    &::before{
      position: absolute;
      top: 50%;
      left: 0;
      width: 25px;
      height: 25px;
      margin-top: -12px;
      border: 2px solid @color-primary;
      border-radius: 50%;
      background-color: transparent;
      background-image: none;
      background-repeat: no-repeat;
      background-position: center center;
      content: '';
      transition: @transition;
    }

    &:hover, &:active{
      //color: @color-primary-hover;
    }
  }

  &:checked{
    & + label{
      &::before{
        background-color: @color-primary;
        background-image: url(img/icon-check.svg);
        border-color: @color-primary;
      }
    }
  }

  &.custom-control-input:checked ~ .custom-control-label::before{
    background-color: @color-primary;
    border-color: @color-primary;;
  }

  &.is-invalid{
    & + label{
      &::before{
        border-color: @color-invalid;
      }
    }

    ~ .invalid-feedback{
      top: 0;
      right: 0;
      left: 45px;
      max-width: none;
      text-align: left;
    }
  }

  .check-circle &{
    & + label{
      &::before{
        width: 30px;
        height: 30px;
        border-color: @color-banner-border;
        border-radius: 50%;
        margin-top: -15px;
      }

      &::after{
        display: none;
      }

      &:hover, &:active{
        //color: @color-primary-hover;
      }
    }

    &:checked{
      & + label{
        color: @color-primary;

        &::before{
          border-color: @color-primary;
        }
      }
    }
  }

  .list-radio &{
    + label{
      padding-left: 45px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-family: BwSurco-Book;
      font-size: 15px;

      &::before{
        width: 34px;
        height: 34px;
        border-color: @color-bg-del;
        top: 0;
        margin: 0;
      }

      &::after{
        display: none;
      }

      &:hover, &:active{
        &::before{
          border-color: @color-primary;
        }

        .title{
          color: @color-primary;
        }
      }
    }

    &:checked{
      & + label{
        &::before{
          border-color: @color-primary;
          background-color: @color-primary;
        }
      }
    }

    &.is-invalid{
      & + label{
        &::before{
          border-color: @color-invalid;
        }
      }

      /* ~ .invalid-feedback{
           top: 0;
           right: 0;
           left: 45px;
           max-width: none;
           text-align: left;
       }*/
    }
  }
}

.has-error{
  input[type=checkbox], input[type=radio]{
    & + label{
      &::before{
        border-color: @color-invalid;
      }
    }

    ~ .invalid-feedback{
      top: 0;
      right: 0;
      left: 45px;
      max-width: none;
      text-align: left;
    }
  }
}

input.checkbox-point{
  & + label{
    display: block;
    width: 34px;
    height: 20px;
    padding: 0;
    user-select: none;

    &::before{
      position: absolute;
      top: 0;
      width: 34px;
      height: 20px;
      margin: 0;
      border: none;
      border-radius: 10px !important;
      background-color: @color-border-form-input;
      background-image: none !important;
      box-shadow: inset 0 2px 3px rgba(0, 0, 0, .2);
      content: '';
      transition: @transition;
    }

    &::after{
      position: absolute;
      top: 2px;
      right: 16px;
      left: auto;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background: @color-white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
      content: '';
      transition: @transition;
    }

    &:hover, &:active, &:focus{
      &::before{
        background-color: @color-primary;
      }
    }
  }

  &:checked{
    & + label{
      &::after{
        right: 2px;
      }
    }
  }

  .left-check.vertical &{
    & + label{
      width: auto;
      margin-bottom: 20px;
      padding-left: 55px;
      background-color: transparent;
      font-size: 15px;
      font-weight: normal;

      &:hover, &:active{
        color: @color-primary;
      }

      &::after{
        right: auto;
        left: 2px;
      }
    }

    &:checked{
      & + label{
        color: @color-primary;

        &::after{
          left: 16px;
        }
      }
    }
  }

  .right-check &{
    & + label{
      width: auto;
      margin-bottom: 20px;
      padding-right: 45px;
      background-color: transparent;
      font-size: 15px;
      font-weight: normal;

      &:hover, &:active{
        color: @color-primary;
      }

      &::before{
        right: 0;
        left: auto;
      }

      &::after{
        right: 16px;
        left: auto;
      }
    }

    &:checked{
      & + label{
        color: @color-primary;

        &::after{
          right: 2px;
        }
      }
    }

    &.danger{
      & + label{
        &:hover, &:active{

          &::before{
            background-color: @color-border-form-input;
          }
        }
      }

      &:checked{
        & + label{
          color: @color-danger;

          &::before{
            background-color: @color-danger;
          }
        }
      }
    }
  }

  .check-inline &{
    & + label{
      display: inline-block;
      width: auto;
      height: auto;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 5px 20px;
      border: 1px solid @color-link-sort;
      border-radius: 50rem;
      background-color: transparent;
      font-family: BwSurco;
      font-size: 14px;

      &:hover, &:active{
        border-color: @color-primary;
        color: @color-primary;
      }

      &::after, &:before{
        display: none;
      }
    }

    &:checked{
      & + label{
        border-color: @color-primary;
        color: @color-white;
        background-color: @color-primary;
      }
    }
  }
}

input[type=checkbox].checkbox-tag, input[type=radio].checkbox-tag{
  .check-inline &{
    & + label{
      display: inline-block;
      width: auto;
      height: auto;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 5px 15px !important;
      border: 1px solid @color-link-sort;
      border-radius: 50rem;
      background-color: transparent;
      font-family: BwSurco;
      font-size: 14px !important;
      background-image: none;
      background-position: 12px center;
      background-repeat: no-repeat;

      &:hover, &:active{
        border-color: @color-primary;
        background-color: @color-primary !important;
        color: @color-white;
      }

      &::after, &:before{
        display: none;
      }
    }

    &:checked{
      & + label{
        border-color: @color-primary;
        background-color: @color-primary !important;;
        color: @color-white;
        background-image: url(img/icon-check-small.svg);
        padding-left: 35px !important;
      }
    }

    &.is-invalid{
      & + label{
        border-color: @color-red;
      }
    }
  }
}

input[type=file]{
  z-index: 5;
  font-size: 0 !important;

  &.file-loading{
    position: relative;
    width: 90px;
    height: 35px;
    padding: 0;
    text-align: right !important;
    text-indent: 134px;

    &::before{
      position: absolute;
      top: 7px;
      left: 10px;
      padding-left: 20px;
      text-indent: 0;
    }
  }

  /*  & + label {
      width:100%;
      height:auto;
      padding:10px 15px;
      border-width:2px;
      border-color:@color-border;
      border-radius:50rem;
      font-size:13px;
      vertical-align:middle;
      cursor:pointer;
      transition:@transition;
      user-select:none;
      &::after {
        top:-2px;
        right:-2px;
        bottom:-2px;
        z-index:5;
        height:auto;
        padding:10px 20px;
        border-width:2px;
        border-color:@color-primary;
        border-radius:50rem;
        color:@color-white;
        background-color:@color-primary;
        font-family:BwSurco-Book;
        font-size:14px;
        transition:@transition;
      }
      &:hover, &:active {
        border-color:@color-primary;
        &::after {
          border-color:@color-primary-hover;
          color:@color-white;
          background-color:@color-primary-hover;
        }
      }
    }*/
}

input[type=password]{
  padding-right: 55px;
}

.input-group{
  margin-bottom: 20px;
  border: 2px solid @color-bg-del;
  border-radius: 50rem;
  align-items: center;

  &-prepend{
    position: relative;
    min-height: 46px;
    align-items: center;
    flex: 1 1 40%;

    &::after{
      display: block;
      position: absolute;
      top: 10px;
      right: -10px;
      bottom: 10px;
      z-index: 5;
      border-right: 2px solid @color-bg-del;
      content: '';
    }
  }

  &-append{
    flex: 1 1 10%;
    justify-content: flex-end;

    .input-group & .btn{
      width: 50px;
      height: 50px;
      margin-top: -2px;
      margin-right: -2px;
      margin-bottom: -2px;
      padding: 0;
      border-radius: 50rem;
      background-image: url(img/icon-plus.svg);
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  & > .custom-select:not(:first-child), & > .form-control:not(:first-child), & > .form-group:not(:first-child){
    margin: 0;
    flex: 1 1 40%;
  }

  & > .full-width{
    flex: 1 1 80%;
  }

  .custom-select, .form-control{
    height: 46px;
    padding: 0 20px;
    border: none;
    border-radius: 50rem 0 0 50rem;
    background-color: transparent;
  }

  .select2-container{
    width: 100% !important;
    height: 46px;
    padding: 0 20px;
    border: none;
    border-radius: 50rem 0 0 50rem;
    background-color: transparent;
  }

  .select2-selection.select2-selection--single{
    display: flex;
    height: 100%;
    padding: 0;
    border: none;
    background-color: transparent;
    align-items: center;
    box-shadow: none;
  }

  .select2-container--krajee-bs4.select2-container--open:not(.select2-container--disabled) .select2-selection, .select2-container--krajee-bs4:not(.select2-container--disabled) .select2-selection:focus{
    border-color: transparent;
    box-shadow: none;
  }

  .select2-container--krajee-bs4 .select2-selection--single .select2-selection__arrow{
    border: none;
  }

  .select2-container .select2-selection--single .select2-selection__rendered{
    width: 100%;
    padding: 0 5px;
  }

  .form-group & .form-control{
    height: 46px;
  }

  .form-control:not(:last-child){
    border-top-right-radius: 50rem;
    border-bottom-right-radius: 50rem;
  }

  &.input-daterange{
    border: none;
    margin: 0;

    .input-group-prepend, .input-group-append{
      display: block;
      flex: 0 0 auto;
      margin: 0;

      .label{
        font-family: BwSurco-Book;
        font-size: 15px;
      }
    }

    .input-group-prepend{
      margin-right: 50px;

      &::after{
        display: none;
      }
    }

    input{
      border: none;
      font-family: BwSurco-Medium;
      font-size: 22px;
      margin: 0;
      padding: 0 0 0 35px;
      width: 160px;
      background-image: url(img/icon-calendar.svg);
      background-repeat: no-repeat;
      background-position: left center;
    }

    svg{
      vertical-align: top;
      margin-top: 5px;
      margin-right: 10px;

      .a{
        fill: #383838;
      }
    }

    .kv-container-from, .kv-container-to{
      display: inline-block;
    }

    .invalid-feedback{
      position: relative;
      top: auto;
      left: 0;
      right: auto;
      text-align: left;
    }
  }

  .with-90{
    flex: 1 1 90%;

    .invalid-feedback{
      top: -20px;
      left: 0;
      max-width: none;
      text-align: left;
    }
  }
}

.form-check{
  .check-wrap &{
    padding: 0;
  }
}

.form-group.required label, .is-required, .form-group.required .has-star:not(.custom-control-label):not(.custom-file-label), .is-required{
  &::after{
    margin-left: 3px;
    color: #2C2C2C;
    font-family: BwSurco-Medium;
    content: '*';
    font-size: 20px;
    line-height: 16px;
  }
}

.input-group-prepend{
  .form-group{
    width: 100%;
  }
}

.form-group + .text-hint{
  margin-top: -25px !important;
}

.input-group + .text-hint{
  margin-top: -10px !important;
}

.checkbox-day{
  margin-right: 8px;
  margin-bottom: 8px;

  label{
    font-family: BwSurco-Medium;
    font-size: 12px;
    text-transform: uppercase;
    top: 0;
    padding: 10px;
    background-color: #FFF;
    border: 2px solid #DEDEDE;
    border-radius: 50%;
    color: #AAA;

    &::before, &::after{
      display: none;
    }
  }

  &:hover{
    label{
      border-color: #1EC8E2;
    }
  }

  input[type=checkbox]:checked{
    + label{
      color: #FFF;
      border-color: #1EC8E2;
      background-color: #1EC8E2;
    }
  }
}

.form-check-inline{
  &.small{
    input[type=checkbox]{
      + label{
        &::after{
          display: none;
        }

        &::before{
          width: 20px;
          height: 20px;
          margin-top: -10px;
          border-color: #DEDEDE;
          background-color: #FFF;
          background-size: 10px 10px;
        }
      }

      &:checked{
        + label{
          &::before{
            border-color: @color-valid;
            background-color: @color-valid;
          }
        }
      }
    }
  }
}

.custom-file{
  height: auto;

  .invalid-feedback{
    position: static;
    max-width: 100%;
    margin-top: -15px;
    text-align: left;
  }
}

.list-radio{
  .custom-control{
    padding: 0;
    margin: 0;

    & + .custom-control{
      margin-top: 15px;
    }
  }

  .title{
    display: block;
  }

  .item-hint{
    display: block;
    font-size: 13px;
    color: @color-specification;
    margin: 12px 0;
  }

  &.block-check-radius{
    .custom-control{
      padding: 20px;
      border-radius: 20px;
      background-color: @color-white;
      border: 2px solid transparent;
      box-shadow: none;
      transition: @transition;

      &:hover{
        border-color: #03C8E5;
      }
    }
  }
}

.parent-hidden-input{
  .invalid-feedback{
    position: relative;
    top: 0;
    right: auto;
    left: auto;
    width: 100%;
    max-width: none;
    margin-top: 0;
    margin-bottom: 10px;
    text-align: left;
  }

  &.text-bold{
    .invalid-feedback{
      font-family: BwSurco-Medium;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

form{
  .item-people{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;

    .form-control{
      padding: 0 !important;
      height: auto;
      background-image: none;
      border: none;
      width: 50px;
      text-align: center;
      box-shadow: none;
      font-family: BwSurco-Medium;
      font-size: 20px;

      &.is-invalid{
        color: @color-ex-price;
      }
    }
  }

  .people-title{
    font-family: BwSurco;
    font-weight: bold;

    span{
      font-family: BwSurco-Book;
      font-size: 13px;
      display: block;
      line-height: 12px;
      font-weight: normal;
    }
  }

  .people-count{
    display: flex;
    align-items: center;

    .form-group{
      margin-bottom: 0;
    }
  }

  .minus, .plus{
    font-size: 26px;
    font-family: BwSurco;
    border: 1px solid @color-main;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    text-align: center;
    line-height: 28px;
    cursor: pointer;
    user-select: none;

    &.disabled{
      color: @color-disabled;
      border-color: @color-disabled;
      cursor: default;
    }
  }
}

.text-hint{
  padding-left: 25px;
  color: @color-text-hint;
  background-image: url(img/icon-info.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  font-family: BwSurco-Book;
  font-size: 13px;
  display: block;
  min-height: 20px;
  line-height: 16px;

  p{
    margin-bottom: 5px;

    &:last-child{
      margin-bottom: 0;
    }
  }

  &.support{
    margin-top: 12px;

    a{
      display: block;
    }
  }

  a{
    color: @color-primary;

    &:hover, &:active{
      text-decoration: underline;
    }
  }

  &.no-image{
    background-image: none;
    padding-left: 0;
  }
}

.g-recaptcha{
  display: flex;
  align-items: center;
  justify-content: center;
}

.field-signupform-recaptcha{
  label{
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1200px){

}

@media (min-width: 768px) and (max-width: 991px){
  input[type=file]{
    & + label{
      height: 42px;
      font-size: 0;

      &::after{
        left: -2px;
        text-align: center;
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 767px){
  input[type=file]{
    & + label{
      height: 42px;
      font-size: 0;

      &::after{
        left: -2px;
        text-align: center;
      }
    }
  }

  .input-group{
    border: 0;

    .form-control:not(:last-child){
      margin-bottom: 10px;
      border: 2px solid #DEDEDE;
      border-radius: 50rem;
    }

    .select2-container{
      margin-bottom: 10px;
      border: 2px solid #DEDEDE;
      border-radius: 50rem;
    }

    &-prepend{
      flex: 1 1 100%;

      &::after{
        display: none;
      }
    }

    &-append{
      flex: 1 1 100%;
    }
  }
}

@media (max-width: 575px){
  input[type=file]{
    & + label{
      height: 42px;
      font-size: 0;

      &::after{
        left: -2px;
        text-align: center;
      }
    }
  }

  .input-group{
    border: 0;

    .form-control:not(:last-child){
      margin-bottom: 10px;
      border: 2px solid #DEDEDE;
      border-radius: 50rem;
    }

    .select2-container{
      margin-bottom: 10px;
      border: 2px solid #DEDEDE;
      border-radius: 50rem;
    }

    &-prepend{
      flex: 1 1 100%;

      &::after{
        display: none;
      }
    }

    &-append{
      flex: 1 1 100%;
    }
  }
}