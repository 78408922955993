/*!
 * @package    yii2-krajee-base
 * @subpackage yii2-widget-activeform
 * @author     Kartik Visweswaran <kartikv2@gmail.com>
 * @copyright  Copyright &copy; Kartik Visweswaran, Krajee.com, 2014 - 2022
 * @version    3.0.2
 *
 * HTML 5 input styling for [Bootstrap 3.x](http://getbootstrap.com)
 * Covers color and range inputs with embedded input group addons.
 *
 * Built for Yii Framework 2.0
 * For more Yii related demos visit http://demos.krajee.com
 */
.input-group-html5 input[type="color"],
.input-group-html5 input[type="range"] {
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
  background: none;
}
.input-group-html5.is-bs3 input[type="color"] {
  max-height: 20px;
}
.input-group-lg input[type="color"] {
  height: 24px !important;
}
.input-group-sm input[type="color"] {
  height: 14px !important;
}
.input-group-html5 input[disabled] {
  opacity: 0.65;
  cursor: not-allowed;
}
.input-group-sp.input-group-addon {
  min-width: 65px;
}
.input-group-lg .input-group-sp.input-group-addon {
  padding: 0;
}
.kv-type-range.kv-range-vertical {
  float: left;
  height: 200px;
  max-width: 80px;
  margin-right: 0.5rem;
  padding: 0 2px;
}
.kv-type-range.kv-range-vertical input.form-control {
  height: 100%;
  line-height: 0;
  text-align: center;
  padding: 0 3px;
}
.kv-type-range.kv-range-vertical input[type=range] {
  height: 100%;
  -ms-writing-mode: bt-lr;
  /* IE */
  -webkit-appearance: slider-vertical;
  /* WebKit */
  padding: 0 3px;
}
.kv-type-range.kv-range-vertical.is-bs3 input.form-control,
.kv-type-range.kv-range-vertical.is-bs3 input[type=range] {
  min-width: 10px;
}
.kv-type-range.kv-range-vertical.is-bs4 input.form-control,
.kv-type-range.kv-range-vertical.is-bs4 input[type=range],
.kv-type-range.kv-range-vertical.is-bs5 input.form-control,
.kv-type-range.kv-range-vertical.is-bs5 input[type=range] {
  width: 10px;
}
.kv-center-loading {
  background: transparent url('../img/loading-plugin.gif') center center no-repeat;
}
