/*!
 * @copyright Copyright &copy; Kartik Visweswaran, Krajee.com, 2014 - 2019
 * @version 1.0.4
 *
 * typeahead.js additional styling
 * 
 * Author: Kartik Visweswaran
 * Copyright: 2014, Kartik Visweswaran, Krajee.com
 * For more JQuery plugins visit http://plugins.krajee.com
 * For more Yii related demos visit http://demos.krajee.com
 */
.tt-scrollable-menu .tt-menu {
  max-height: 140px;
  overflow-y: auto;
}
.tt-rtl .tt-menu {
  text-align: right;
}
.tt-input.loading {
  background: transparent url('../img/loading.gif') no-repeat scroll right center content-box !important;
}
.twitter-typeahead input[disabled],
input[readonly],
fieldset[disabled] .twitter-typeahead input {
  background-color: #EEEEEE;
  cursor: not-allowed;
}
