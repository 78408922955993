.form-group {
  position: relative;
  margin-bottom: 29px;
}
.form-group label {
  position: absolute;
  top: -15px;
  left: 35px;
  margin: 0;
  padding: 5px 10px;
  background-color: #FFF;
  font-family: BwSurco-Medium;
  font-size: 16px;
  user-select: none;
}
.form-group .icon {
  position: absolute;
  top: 50%;
  left: 22px;
  margin-top: -11px;
}
.form-group .icon .a {
  transition: all 0.3s;
  fill: #c3c3c3;
}
.form-group .pass {
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
}
.form-group .pass.hide {
  margin-top: -10px;
}
.form-group .pass.show {
  margin-top: -12px;
}
.form-group .pass .a {
  transition: all 0.3s;
  fill: #D0D0D0;
}
.form-group .pass:hover .a,
.form-group .pass:active .a {
  fill: #2C2C2C;
}
.form-group .key {
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -11px;
}
.form-group .key .a {
  transition: all 0.3s;
  fill: #363636;
}
.form-group:active .key .a,
.form-group:hover .key .a {
  transition: all 0.3s;
  fill: #03c8e5;
}
.form-group.has-error .icon,
.form-group.has-success .icon {
  display: none;
}
.form-group .file-input {
  margin-top: 10px;
}
.form-group .file-input .file-caption {
  width: auto;
}
.form-group .file-input .file-caption .file-caption-name {
  font-family: BwSurco-Medium;
  font-size: 13px;
}
.form-group .file-input .input-group-append {
  width: auto;
}
.form-group .file-input .input-group-append .btn {
  width: 100%;
  height: 44px;
  padding: 10px 20px;
  background-image: none;
  font-family: BwSurco-Book;
  font-size: 14px;
}
.form-group .file-input .input-group-append .btn span {
  cursor: inherit;
}
.form-group .file-input .form-control {
  height: 40px;
  padding: 8px 15px;
}
.form-group .file-input .file-preview .icon-delete {
  opacity: 1;
}
.form-group .file-input .invalid-feedback {
  position: static;
  width: 100%;
  max-width: none;
  margin: 10px 0;
  padding: 15px;
  background-color: #c3c3c3;
  font-size: 16px;
  text-align: left;
}
.form-group .file-input .invalid-feedback pre {
  overflow: visible;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
  font-family: BwSurco-Book;
  white-space: normal;
}
.form-control {
  height: 60px;
  padding: 12px 55px 12px 15px;
  border: 2px solid #dfdfdf;
  border-radius: 30px;
  font-family: BwSurco-Medium;
}
.form-control::placeholder {
  color: #242424;
}
.form-control:focus,
.form-control:active,
.form-control:hover {
  border-color: #03c8e5;
  outline: 0;
  color: #363636;
  background-color: inherit;
  box-shadow: 0 0 10px 2px rgba(3, 200, 229, 0.25);
}
.form-control:focus + .icon .a,
.form-control:active + .icon .a,
.form-control:hover + .icon .a {
  fill: #03c8e5;
}
textarea.form-control {
  resize: none;
  border-radius: 20px;
}
.form-control.is-invalid {
  border-color: #e51e03;
  background-image: url(img/icon-error.svg);
  background-position: calc(100% - 15px) center;
}
.form-control.is-invalid:focus,
.form-control.is-invalid:active,
.form-control.is-invalid:hover {
  box-shadow: none;
}
.form-control.is-invalid + .icon {
  display: none;
}
.form-control.is-valid {
  border-color: #03c8e5;
  background-image: url(img/icon-valid.svg);
  background-position: calc(100% - 15px) center;
}
textarea.form-control.is-valid {
  background-position: calc(100% - 15px) 15px;
}
.form-control.is-valid:focus,
.form-control.is-valid:active,
.form-control.is-valid:hover {
  box-shadow: none;
  border-color: #03c8e5 !important;
}
.form-control.is-valid + .icon {
  display: none;
}
.form-control.is-valid:-webkit-autofill,
.form-control.is-valid:-webkit-autofill:hover,
.form-control.is-valid:-webkit-autofill:focus,
.form-control.is-valid:-webkit-autofill:active {
  border-color: #03c8e5 !important;
  background-image: url(img/icon-valid.svg) !important;
}
.form-control-plaintext {
  padding: 0;
  font-family: BwSurco-Book;
  font-size: 15px;
  line-height: normal;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
}
.form-control.password-input {
  padding-left: 55px;
  padding-right: 55px;
  background-position: 20px center;
}
.form-control.pass-with-icon {
  padding-left: 55px;
}
.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #03c8e5;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #03c8e5;
  background-color: #03c8e5;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #03c8e5;
}
.invalid-feedback {
  display: block;
  position: absolute;
  top: -20px;
  right: 25px;
  left: 25px;
  z-index: 5;
  max-width: 85%;
  color: #EC3106;
  font-family: BwSurco-Book;
  font-size: 13px;
  line-height: 12px;
  text-align: right;
}
input[type=checkbox],
input[type=radio] {
  display: none;
}
input[type=checkbox] + label,
input[type=radio] + label {
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
}
input[type=checkbox] + label::before,
input[type=radio] + label::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 25px;
  height: 25px;
  margin-top: -12px;
  border: 2px solid #03c8e5;
  border-radius: 50%;
  background-color: transparent;
  background-image: none;
  background-repeat: no-repeat;
  background-position: center center;
  content: '';
  transition: all 0.3s;
}
input[type=checkbox]:checked + label::before,
input[type=radio]:checked + label::before {
  background-color: #03c8e5;
  background-image: url(img/icon-check.svg);
  border-color: #03c8e5;
}
input[type=checkbox].custom-control-input:checked ~ .custom-control-label::before,
input[type=radio].custom-control-input:checked ~ .custom-control-label::before {
  background-color: #03c8e5;
  border-color: #03c8e5;
}
input[type=checkbox].is-invalid + label::before,
input[type=radio].is-invalid + label::before {
  border-color: #ec3106;
}
input[type=checkbox].is-invalid ~ .invalid-feedback,
input[type=radio].is-invalid ~ .invalid-feedback {
  top: 0;
  right: 0;
  left: 45px;
  max-width: none;
  text-align: left;
}
.check-circle input[type=checkbox] + label::before,
.check-circle input[type=radio] + label::before {
  width: 30px;
  height: 30px;
  border-color: #e9e9e9;
  border-radius: 50%;
  margin-top: -15px;
}
.check-circle input[type=checkbox] + label::after,
.check-circle input[type=radio] + label::after {
  display: none;
}
.check-circle input[type=checkbox]:checked + label,
.check-circle input[type=radio]:checked + label {
  color: #03c8e5;
}
.check-circle input[type=checkbox]:checked + label::before,
.check-circle input[type=radio]:checked + label::before {
  border-color: #03c8e5;
}
.list-radio input[type=checkbox] + label,
.list-radio input[type=radio] + label {
  padding-left: 45px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: BwSurco-Book;
  font-size: 15px;
}
.list-radio input[type=checkbox] + label::before,
.list-radio input[type=radio] + label::before {
  width: 34px;
  height: 34px;
  border-color: #dedede;
  top: 0;
  margin: 0;
}
.list-radio input[type=checkbox] + label::after,
.list-radio input[type=radio] + label::after {
  display: none;
}
.list-radio input[type=checkbox] + label:hover::before,
.list-radio input[type=radio] + label:hover::before,
.list-radio input[type=checkbox] + label:active::before,
.list-radio input[type=radio] + label:active::before {
  border-color: #03c8e5;
}
.list-radio input[type=checkbox] + label:hover .title,
.list-radio input[type=radio] + label:hover .title,
.list-radio input[type=checkbox] + label:active .title,
.list-radio input[type=radio] + label:active .title {
  color: #03c8e5;
}
.list-radio input[type=checkbox]:checked + label::before,
.list-radio input[type=radio]:checked + label::before {
  border-color: #03c8e5;
  background-color: #03c8e5;
}
.list-radio input[type=checkbox].is-invalid,
.list-radio input[type=radio].is-invalid {
  /* ~ .invalid-feedback{
           top: 0;
           right: 0;
           left: 45px;
           max-width: none;
           text-align: left;
       }*/
}
.list-radio input[type=checkbox].is-invalid + label::before,
.list-radio input[type=radio].is-invalid + label::before {
  border-color: #ec3106;
}
.has-error input[type=checkbox] + label::before,
.has-error input[type=radio] + label::before {
  border-color: #ec3106;
}
.has-error input[type=checkbox] ~ .invalid-feedback,
.has-error input[type=radio] ~ .invalid-feedback {
  top: 0;
  right: 0;
  left: 45px;
  max-width: none;
  text-align: left;
}
input.checkbox-point + label {
  display: block;
  width: 34px;
  height: 20px;
  padding: 0;
  user-select: none;
}
input.checkbox-point + label::before {
  position: absolute;
  top: 0;
  width: 34px;
  height: 20px;
  margin: 0;
  border: none;
  border-radius: 10px !important;
  background-color: #dfdfdf;
  background-image: none !important;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
  content: '';
  transition: all 0.3s;
}
input.checkbox-point + label::after {
  position: absolute;
  top: 2px;
  right: 16px;
  left: auto;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  content: '';
  transition: all 0.3s;
}
input.checkbox-point + label:hover::before,
input.checkbox-point + label:active::before,
input.checkbox-point + label:focus::before {
  background-color: #03c8e5;
}
input.checkbox-point:checked + label::after {
  right: 2px;
}
.left-check.vertical input.checkbox-point + label {
  width: auto;
  margin-bottom: 20px;
  padding-left: 55px;
  background-color: transparent;
  font-size: 15px;
  font-weight: normal;
}
.left-check.vertical input.checkbox-point + label:hover,
.left-check.vertical input.checkbox-point + label:active {
  color: #03c8e5;
}
.left-check.vertical input.checkbox-point + label::after {
  right: auto;
  left: 2px;
}
.left-check.vertical input.checkbox-point:checked + label {
  color: #03c8e5;
}
.left-check.vertical input.checkbox-point:checked + label::after {
  left: 16px;
}
.right-check input.checkbox-point + label {
  width: auto;
  margin-bottom: 20px;
  padding-right: 45px;
  background-color: transparent;
  font-size: 15px;
  font-weight: normal;
}
.right-check input.checkbox-point + label:hover,
.right-check input.checkbox-point + label:active {
  color: #03c8e5;
}
.right-check input.checkbox-point + label::before {
  right: 0;
  left: auto;
}
.right-check input.checkbox-point + label::after {
  right: 16px;
  left: auto;
}
.right-check input.checkbox-point:checked + label {
  color: #03c8e5;
}
.right-check input.checkbox-point:checked + label::after {
  right: 2px;
}
.right-check input.checkbox-point.danger + label:hover::before,
.right-check input.checkbox-point.danger + label:active::before {
  background-color: #dfdfdf;
}
.right-check input.checkbox-point.danger:checked + label {
  color: #ea5050;
}
.right-check input.checkbox-point.danger:checked + label::before {
  background-color: #ea5050;
}
.check-inline input.checkbox-point + label {
  display: inline-block;
  width: auto;
  height: auto;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px 20px;
  border: 1px solid #383838;
  border-radius: 50rem;
  background-color: transparent;
  font-family: BwSurco;
  font-size: 14px;
}
.check-inline input.checkbox-point + label:hover,
.check-inline input.checkbox-point + label:active {
  border-color: #03c8e5;
  color: #03c8e5;
}
.check-inline input.checkbox-point + label::after,
.check-inline input.checkbox-point + label:before {
  display: none;
}
.check-inline input.checkbox-point:checked + label {
  border-color: #03c8e5;
  color: #ffffff;
  background-color: #03c8e5;
}
.check-inline input[type=checkbox].checkbox-tag + label,
.check-inline input[type=radio].checkbox-tag + label {
  display: inline-block;
  width: auto;
  height: auto;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px 15px !important;
  border: 1px solid #383838;
  border-radius: 50rem;
  background-color: transparent;
  font-family: BwSurco;
  font-size: 14px !important;
  background-image: none;
  background-position: 12px center;
  background-repeat: no-repeat;
}
.check-inline input[type=checkbox].checkbox-tag + label:hover,
.check-inline input[type=radio].checkbox-tag + label:hover,
.check-inline input[type=checkbox].checkbox-tag + label:active,
.check-inline input[type=radio].checkbox-tag + label:active {
  border-color: #03c8e5;
  background-color: #03c8e5 !important;
  color: #ffffff;
}
.check-inline input[type=checkbox].checkbox-tag + label::after,
.check-inline input[type=radio].checkbox-tag + label::after,
.check-inline input[type=checkbox].checkbox-tag + label:before,
.check-inline input[type=radio].checkbox-tag + label:before {
  display: none;
}
.check-inline input[type=checkbox].checkbox-tag:checked + label,
.check-inline input[type=radio].checkbox-tag:checked + label {
  border-color: #03c8e5;
  background-color: #03c8e5 !important;
  color: #ffffff;
  background-image: url(img/icon-check-small.svg);
  padding-left: 35px !important;
}
.check-inline input[type=checkbox].checkbox-tag.is-invalid + label,
.check-inline input[type=radio].checkbox-tag.is-invalid + label {
  border-color: #e51e03;
}
input[type=file] {
  z-index: 5;
  font-size: 0 !important;
  /*  & + label {
      width:100%;
      height:auto;
      padding:10px 15px;
      border-width:2px;
      border-color:@color-border;
      border-radius:50rem;
      font-size:13px;
      vertical-align:middle;
      cursor:pointer;
      transition:@transition;
      user-select:none;
      &::after {
        top:-2px;
        right:-2px;
        bottom:-2px;
        z-index:5;
        height:auto;
        padding:10px 20px;
        border-width:2px;
        border-color:@color-primary;
        border-radius:50rem;
        color:@color-white;
        background-color:@color-primary;
        font-family:BwSurco-Book;
        font-size:14px;
        transition:@transition;
      }
      &:hover, &:active {
        border-color:@color-primary;
        &::after {
          border-color:@color-primary-hover;
          color:@color-white;
          background-color:@color-primary-hover;
        }
      }
    }*/
}
input[type=file].file-loading {
  position: relative;
  width: 90px;
  height: 35px;
  padding: 0;
  text-align: right !important;
  text-indent: 134px;
}
input[type=file].file-loading::before {
  position: absolute;
  top: 7px;
  left: 10px;
  padding-left: 20px;
  text-indent: 0;
}
input[type=password] {
  padding-right: 55px;
}
.input-group {
  margin-bottom: 20px;
  border: 2px solid #dedede;
  border-radius: 50rem;
  align-items: center;
}
.input-group-prepend {
  position: relative;
  min-height: 46px;
  align-items: center;
  flex: 1 1 40%;
}
.input-group-prepend::after {
  display: block;
  position: absolute;
  top: 10px;
  right: -10px;
  bottom: 10px;
  z-index: 5;
  border-right: 2px solid #dedede;
  content: '';
}
.input-group-append {
  flex: 1 1 10%;
  justify-content: flex-end;
}
.input-group .input-group-append .btn {
  width: 50px;
  height: 50px;
  margin-top: -2px;
  margin-right: -2px;
  margin-bottom: -2px;
  padding: 0;
  border-radius: 50rem;
  background-image: url(img/icon-plus.svg);
  background-repeat: no-repeat;
  background-position: center center;
}
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child),
.input-group > .form-group:not(:first-child) {
  margin: 0;
  flex: 1 1 40%;
}
.input-group > .full-width {
  flex: 1 1 80%;
}
.input-group .custom-select,
.input-group .form-control {
  height: 46px;
  padding: 0 20px;
  border: none;
  border-radius: 50rem 0 0 50rem;
  background-color: transparent;
}
.input-group .select2-container {
  width: 100% !important;
  height: 46px;
  padding: 0 20px;
  border: none;
  border-radius: 50rem 0 0 50rem;
  background-color: transparent;
}
.input-group .select2-selection.select2-selection--single {
  display: flex;
  height: 100%;
  padding: 0;
  border: none;
  background-color: transparent;
  align-items: center;
  box-shadow: none;
}
.input-group .select2-container--krajee-bs4.select2-container--open:not(.select2-container--disabled) .select2-selection,
.input-group .select2-container--krajee-bs4:not(.select2-container--disabled) .select2-selection:focus {
  border-color: transparent;
  box-shadow: none;
}
.input-group .select2-container--krajee-bs4 .select2-selection--single .select2-selection__arrow {
  border: none;
}
.input-group .select2-container .select2-selection--single .select2-selection__rendered {
  width: 100%;
  padding: 0 5px;
}
.form-group .input-group .form-control {
  height: 46px;
}
.input-group .form-control:not(:last-child) {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}
.input-group.input-daterange {
  border: none;
  margin: 0;
}
.input-group.input-daterange .input-group-prepend,
.input-group.input-daterange .input-group-append {
  display: block;
  flex: 0 0 auto;
  margin: 0;
}
.input-group.input-daterange .input-group-prepend .label,
.input-group.input-daterange .input-group-append .label {
  font-family: BwSurco-Book;
  font-size: 15px;
}
.input-group.input-daterange .input-group-prepend {
  margin-right: 50px;
}
.input-group.input-daterange .input-group-prepend::after {
  display: none;
}
.input-group.input-daterange input {
  border: none;
  font-family: BwSurco-Medium;
  font-size: 22px;
  margin: 0;
  padding: 0 0 0 35px;
  width: 160px;
  background-image: url(img/icon-calendar.svg);
  background-repeat: no-repeat;
  background-position: left center;
}
.input-group.input-daterange svg {
  vertical-align: top;
  margin-top: 5px;
  margin-right: 10px;
}
.input-group.input-daterange svg .a {
  fill: #383838;
}
.input-group.input-daterange .kv-container-from,
.input-group.input-daterange .kv-container-to {
  display: inline-block;
}
.input-group.input-daterange .invalid-feedback {
  position: relative;
  top: auto;
  left: 0;
  right: auto;
  text-align: left;
}
.input-group .with-90 {
  flex: 1 1 90%;
}
.input-group .with-90 .invalid-feedback {
  top: -20px;
  left: 0;
  max-width: none;
  text-align: left;
}
.check-wrap .form-check {
  padding: 0;
}
.form-group.required label::after,
.is-required::after,
.form-group.required .has-star:not(.custom-control-label):not(.custom-file-label)::after,
.is-required::after {
  margin-left: 3px;
  color: #2C2C2C;
  font-family: BwSurco-Medium;
  content: '*';
  font-size: 20px;
  line-height: 16px;
}
.input-group-prepend .form-group {
  width: 100%;
}
.form-group + .text-hint {
  margin-top: -25px !important;
}
.input-group + .text-hint {
  margin-top: -10px !important;
}
.checkbox-day {
  margin-right: 8px;
  margin-bottom: 8px;
}
.checkbox-day label {
  font-family: BwSurco-Medium;
  font-size: 12px;
  text-transform: uppercase;
  top: 0;
  padding: 10px;
  background-color: #FFF;
  border: 2px solid #DEDEDE;
  border-radius: 50%;
  color: #AAA;
}
.checkbox-day label::before,
.checkbox-day label::after {
  display: none;
}
.checkbox-day:hover label {
  border-color: #1EC8E2;
}
.checkbox-day input[type=checkbox]:checked + label {
  color: #FFF;
  border-color: #1EC8E2;
  background-color: #1EC8E2;
}
.form-check-inline.small input[type=checkbox] + label::after {
  display: none;
}
.form-check-inline.small input[type=checkbox] + label::before {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  border-color: #DEDEDE;
  background-color: #FFF;
  background-size: 10px 10px;
}
.form-check-inline.small input[type=checkbox]:checked + label::before {
  border-color: #03c8e5;
  background-color: #03c8e5;
}
.custom-file {
  height: auto;
}
.custom-file .invalid-feedback {
  position: static;
  max-width: 100%;
  margin-top: -15px;
  text-align: left;
}
.list-radio .custom-control {
  padding: 0;
  margin: 0;
}
.list-radio .custom-control + .custom-control {
  margin-top: 15px;
}
.list-radio .title {
  display: block;
}
.list-radio .item-hint {
  display: block;
  font-size: 13px;
  color: #a9a9a9;
  margin: 12px 0;
}
.list-radio.block-check-radius .custom-control {
  padding: 20px;
  border-radius: 20px;
  background-color: #ffffff;
  border: 2px solid transparent;
  box-shadow: none;
  transition: all 0.3s;
}
.list-radio.block-check-radius .custom-control:hover {
  border-color: #03C8E5;
}
.parent-hidden-input .invalid-feedback {
  position: relative;
  top: 0;
  right: auto;
  left: auto;
  width: 100%;
  max-width: none;
  margin-top: 0;
  margin-bottom: 10px;
  text-align: left;
}
.parent-hidden-input.text-bold .invalid-feedback {
  font-family: BwSurco-Medium;
  font-size: 16px;
  font-weight: bold;
}
form .item-people {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}
form .item-people .form-control {
  padding: 0 !important;
  height: auto;
  background-image: none;
  border: none;
  width: 50px;
  text-align: center;
  box-shadow: none;
  font-family: BwSurco-Medium;
  font-size: 20px;
}
form .item-people .form-control.is-invalid {
  color: #ea1c1c;
}
form .people-title {
  font-family: BwSurco;
  font-weight: bold;
}
form .people-title span {
  font-family: BwSurco-Book;
  font-size: 13px;
  display: block;
  line-height: 12px;
  font-weight: normal;
}
form .people-count {
  display: flex;
  align-items: center;
}
form .people-count .form-group {
  margin-bottom: 0;
}
form .minus,
form .plus {
  font-size: 26px;
  font-family: BwSurco;
  border: 1px solid #363636;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
  user-select: none;
}
form .minus.disabled,
form .plus.disabled {
  color: #e0e0e0;
  border-color: #e0e0e0;
  cursor: default;
}
.text-hint {
  padding-left: 25px;
  color: #b8b8b8;
  background-image: url(img/icon-info.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  font-family: BwSurco-Book;
  font-size: 13px;
  display: block;
  min-height: 20px;
  line-height: 16px;
}
.text-hint p {
  margin-bottom: 5px;
}
.text-hint p:last-child {
  margin-bottom: 0;
}
.text-hint.support {
  margin-top: 12px;
}
.text-hint.support a {
  display: block;
}
.text-hint a {
  color: #03c8e5;
}
.text-hint a:hover,
.text-hint a:active {
  text-decoration: underline;
}
.text-hint.no-image {
  background-image: none;
  padding-left: 0;
}
.g-recaptcha {
  display: flex;
  align-items: center;
  justify-content: center;
}
.field-signupform-recaptcha label {
  display: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  input[type=file] + label {
    height: 42px;
    font-size: 0;
  }
  input[type=file] + label::after {
    left: -2px;
    text-align: center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  input[type=file] + label {
    height: 42px;
    font-size: 0;
  }
  input[type=file] + label::after {
    left: -2px;
    text-align: center;
  }
  .input-group {
    border: 0;
  }
  .input-group .form-control:not(:last-child) {
    margin-bottom: 10px;
    border: 2px solid #DEDEDE;
    border-radius: 50rem;
  }
  .input-group .select2-container {
    margin-bottom: 10px;
    border: 2px solid #DEDEDE;
    border-radius: 50rem;
  }
  .input-group-prepend {
    flex: 1 1 100%;
  }
  .input-group-prepend::after {
    display: none;
  }
  .input-group-append {
    flex: 1 1 100%;
  }
}
@media (max-width: 575px) {
  input[type=file] + label {
    height: 42px;
    font-size: 0;
  }
  input[type=file] + label::after {
    left: -2px;
    text-align: center;
  }
  .input-group {
    border: 0;
  }
  .input-group .form-control:not(:last-child) {
    margin-bottom: 10px;
    border: 2px solid #DEDEDE;
    border-radius: 50rem;
  }
  .input-group .select2-container {
    margin-bottom: 10px;
    border: 2px solid #DEDEDE;
    border-radius: 50rem;
  }
  .input-group-prepend {
    flex: 1 1 100%;
  }
  .input-group-prepend::after {
    display: none;
  }
  .input-group-append {
    flex: 1 1 100%;
  }
}
