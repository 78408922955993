@import '../_variables';

.btn{
    cursor: pointer;
    
    &.focus, &:focus{
        outline: 0;
        box-shadow: none;
    }
}

.btn-primary{
    height: 60px;
    padding: 16px 35px;
    border-color: @color-primary;
    border-radius: 30px;
    color: @color-white;
    background-color: @color-primary;
    box-shadow: 0 10px 29px 0 rgba(3, 200, 229, .41);
    
    &:hover, &:active, &:focus{
        border-color: @color-primary-hover;
        background-color: @color-primary-hover;
        box-shadow: 0 10px 29px 0 rgba(3, 200, 229, .41);
    }
    
    &.disabled, &:disabled{
        border-color: @color-primary-hover;
        background-color: @color-primary-hover;
        box-shadow: 0 10px 29px 0 rgba(3, 200, 229, .41);
    }
    
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus{
        border-color: @color-primary-hover;
        background-color: @color-primary-hover;
        box-shadow: 0 10px 29px 0 rgba(3, 200, 229, .41);
    }
    
    &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active{
        border-color: @color-primary-hover;
        background-color: @color-primary-hover;
        box-shadow: 0 10px 29px 0 rgba(3, 200, 229, .41);
    }
}

.btn-default{
    height: 60px;
    padding: 16px 35px;
    border: 2px solid @color-primary;
    border-radius: 30px;
    color: @color-primary;
    box-shadow: 0 5px 15px 0 rgba(3, 200, 229, .4);
    
    &:hover, &:active, &:focus{
        border-color: @color-primary;
        color: @color-white;
        background-color: @color-primary;
    }
    
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus{
        border-color: @color-primary;
        background-color: @color-primary;
        box-shadow: 0 5px 15px 0 rgba(3, 200, 229, .4);
    }
}

.btn-light{
    padding: 10px 50px;
    border-radius: 50rem;
    background-color: @color-white;
    box-shadow: 3px 5.196px 21px 0 rgba(186, 186, 186, .51);
    font-family: BwSurco;
    font-size: 18px;
    font-weight: bold;
    
    &:hover, &:active, &:focus{
        border-color: @color-white;
        color: @color-main;
        background-color: @color-white;
    }
    
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus{
        border-color: @color-white;
        background-color: @color-white;
        box-shadow: none;
    }
}

.btn-dark{
    padding: 10px 50px;
    border-width: 2px;
    border-color: @color-main;
    border-radius: 50rem;
    color: @color-main;
    background-color: @color-white;
    box-shadow: none !important;
    font-family: BwSurco-Medium;
    font-size: 15px;
}

.btn-success{
    height: 60px;
    padding: 16px 35px;
    border: 1px solid @color-success;
    border-radius: 30px;
    color: @color-white;
    background-color: @color-success;
    transition: @transition;
    
    &:hover, &:active, &:focus{
        border-color: @color-success-hover;
        background-color: @color-success-hover;
    }
}

.btn-green{
    height: 60px;
    padding: 16px 35px;
    border: 1px solid @color-green;
    border-radius: 30px;
    color: @color-white;
    background-color: @color-green;
    transition: @transition;
    
    &:hover, &:active, &:focus{
        border-color: @color-green-active;
        background-color: @color-green-active;
        color: @color-white;
    }
}

.btn-empty-primary, .btn-empty-primary-blue, .btn-empty-success, .btn-empty-danger, .btn-empty-warning, .btn-empty-black{
    border: 2px solid;
    background-color: @color-white;
    height: 34px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 50rem;
    padding: 4px 20px;
    transition: @transition;
    
    svg{
        margin-right: 10px;
        transition: @transition;
    }
    
    &:hover, &:active, &:focus{
        color: @color-white;
        
        svg{
            fill: @color-white;
        }
    }
    
    &.print{
        padding: 12px 30px;
        height: 54px;
        font-size: 15px;
    }
}

.btn-empty-primary{
    border-color: @color-primary;
    
    svg{
        fill: @color-primary;
    }
    
    &:hover, &:active, &:focus{
        background-color: @color-primary;
    }
    
}

.btn-empty-primary-blue{
    font-weight: normal;
    font-family: BwSurco-Medium;
    color: @color-primary;
    border-color: @color-primary;
    
    svg{
        fill: @color-primary;
    }
    
    &:hover, &:active, &:focus{
        background-color: @color-primary;
    }
}

.btn-empty-success{
    border-color: @color-success;
    
    svg{
        fill: @color-success;
    }
    
    &:hover, &:active, &:focus{
        background-color: @color-success;
    }
}

.btn-empty-danger{
    border-color: @color-danger;
    
    svg{
        fill: @color-danger;
    }
    
    &:hover, &:active, &:focus{
        background-color: @color-danger;
    }
}

.btn-empty-warning{
    border-color: @color-bg-waiting;
    
    svg{
        fill: @color-bg-waiting;
    }
    
    &:hover, &:active, &:focus{
        background-color: @color-bg-waiting;
    }
}

.btn-empty-danger-red{
    border: 2px solid @color-danger;
    background-color: @color-danger;
    color: @color-white;
    height: 34px;
    font-weight: normal;
    font-family: BwSurco-Medium;
    font-size: 14px;
    border-radius: 50rem;
    padding: 4px 20px;
    transition: @transition;
    
    svg{
        fill: @color-danger;
        margin-right: 10px;
        transition: @transition;
    }
}

.btn-empty-black{
    border-color: @color-main;
    
    svg{
        fill: @color-main;
    }
    
    &:hover, &:active, &:focus{
        color: @color-white;
        background-color: @color-main;
    }
}

.load-more{
    display: inline-block;
    margin: 60px auto 80px;
    font-weight: bold;
    cursor: pointer;
    
    svg{
        .a{
            fill: #ffffff;
        }
    }
    
    &.load{
        svg{
            animation-name: spin;
            animation-duration: 1000ms;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }
    }
    
    &.disabled{
        cursor: not-allowed;
    }
    
    .text{
        margin-left: 20px;
    }
}

.btn-sort{
    position: absolute;
    top: 0;
    right: 7px;
    font-size: 24px;
}

.btn.icon-file-delete{
    border-radius: 50%;
    background-color: @color-bg-del;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    position: absolute;
    left: 10px;
    top: 5px;
    z-index: 15;
    transition: @transition;
    
    svg{
        .a{
            fill: @color-text-datepicker;
            transition: @transition;
        }
    }
    
    &:hover, &:active{
        background-color: @color-bg-del-hover;
        
        svg{
            .a{
                fill: @color-white;
            }
        }
    }
}

.btn.disabled, .btn:disabled{
    opacity: 1;
    cursor: no-drop;
}

button{
    outline: none !important;
}

@media (min-width: 992px) and (max-width: 1200px){

}

@media (min-width: 768px) and (max-width: 991px){
    .load-more{
        margin: 0 0 30px;
    }
}

@media (min-width: 576px) and (max-width: 767px){
    .load-more{
        margin: 0 0 30px;
    }
}

@media (max-width: 575px){
    .load-more{
        margin: 0 0 30px;
    }
}