/*!
 * @copyright Copyright &copy; Kartik Visweswaran, Krajee.com, 2015 - 2019
 * @package yii2-widgets
 * @subpackage yii2-widget-activeform
 * @version 1.5.9
 *
 * Active Form Styling for Bootstrap 3.x & Bootstrap 4.x
 * Built for Yii Framework 2.0
 * For more Yii related demos visit http://demos.krajee.com
 */
.kv-hint-icon {
  display: inline-block;
  float: right;
  font-size: 16px;
  margin-left: 4px;
  vertical-align: middle;
  cursor: help;
  opacity: 0.8;
}
.kv-hint-icon:hover,
.kv-hint-icon:focus {
  opacity: 1;
}
.kv-hint-content {
  opacity: 0.9;
}
.kv-hint-label {
  border-bottom: 1px dashed #888;
  cursor: help;
}
label.disabled,
label.readonly,
.disabled label,
.readonly label {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65;
}
@media (min-width: 768px) {
  .form-inline .form-group,
  .form-inline .form-control {
    vertical-align: top;
  }
  .form-inline .btn-default {
    margin: 0;
  }
  .form-inline .checkbox,
  .form-inline .radio {
    padding-top: 6px;
  }
}
.input-multiselect {
  overflow: auto;
  min-height: 145px !important;
}
.kv-form-bs4.tooltip-feedback .form-group.has-error {
  position: relative;
  margin-bottom: 2rem;
}
/**
 * feedback icon styling
 */
.kv-form-bs4 .has-feedback {
  position: relative;
}
.kv-form-bs4 .has-feedback .form-control {
  padding-right: 2.65625rem;
}
.kv-form-bs4 .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 2.125rem;
  height: 2.125rem;
  line-height: 2.125rem;
  text-align: center;
  pointer-events: none;
}
.has-feedback.has-size-lg .form-control-feedback {
  font-size: 18px;
  top: 31px;
}
.has-feedback.has-size-sm .form-control-feedback {
  font-size: 12px;
}
.kv-form-bs4 .has-feedback.has-size-lg .form-control-feedback {
  width: 2.875rem;
  height: 2.875rem;
  line-height: 2.875rem;
  font-size: 1.25rem;
}
.kv-form-bs4 .has-feedback.has-size-sm .form-control-feedback {
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.75rem;
  font-size: 0.875rem;
}
.has-error .form-control-feedback {
  color: #a94442;
}
.kv-form-bs4 .has-feedback label ~ .form-control-feedback {
  top: 2.125rem;
}
.kv-form-bs4 .has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}
.kv-feedback-success,
.kv-feedback-error,
.has-error .kv-feedback-default,
.has-success .kv-feedback-default {
  display: none;
}
.has-error .kv-feedback-error,
.has-success .kv-feedback-success {
  display: inline-block;
}
@media (min-width: 768px) {
  .kv-form-bs4.form-inline .has-feedback .form-control-feedback,
  .kv-form-bs4.navbar-form .has-feedback .form-control-feedback {
    top: 0;
  }
}
.kv-form-bs4.form-horizontal .has-feedback .form-control-feedback {
  right: 0.9375rem;
}
/**
 * multiple addons border fix
 **/
.input-group .input-group-addon:not(:last-child) {
  border-right: 0;
}
.input-group .input-group-addon:last-child {
  border-left: 1px solid #ccc;
}
.form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}
/**
 * addons success and error states
 **/
.has-success.highlight-addon .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d;
}
.has-error.highlight-addon .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}
.has-success.highlight-addon .input-group-btn .btn:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
.has-success.highlight-addon .input-group-btn .btn:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
.has-error.highlight-addon .input-group-btn .btn:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
.has-error.highlight-addon .input-group-btn .btn:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
.kv-form-bs4 .has-success.highlight-addon .input-group-text {
  color: #28a745;
  background-color: #d4edda;
  border-color: #28a745;
}
.kv-form-bs4 .has-error.highlight-addon .input-group-text {
  color: #dc3545;
  background-color: #f8d7da;
  border-color: #dc3545;
}
.kv-form-bs4 .has-success.highlight-addon .input-group .btn:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.kv-form-bs4 .has-success.highlight-addon .input-group .btn:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.kv-form-bs4 .has-success.highlight-addon .input-group .btn:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.kv-form-bs4 .has-error.highlight-addon .input-group .btn:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.kv-form-bs4 .has-error.highlight-addon .input-group .btn:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.kv-form-bs4 .has-error.highlight-addon .input-group .btn:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.kv-form-bs4 .has-error .invalid-feedback {
  display: block;
}
.kv-form-bs4 .hint-block {
  font-size: 0.75rem;
  margin-top: 0.375rem;
  color: #999;
}
.checkbox.not-enclosed,
.radio.not-enclosed {
  padding-left: 20px;
}
.checkbox.not-enclosed label,
.radio.not-enclosed label {
  padding-left: 2px;
}
.form-inline .checkbox.not-enclosed,
.form-inline .radio.not-enclosed {
  padding-left: 5px;
  padding-right: 5px;
}
.form-inline .checkbox.not-enclosed label,
.form-inline .radio.not-enclosed label {
  margin-top: -5px;
}
.form-group.required .has-star:not(.custom-control-label):not(.custom-file-label)::after,
.is-required::after {
  content: "*";
  margin-left: 3px;
  font-weight: normal;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  color: tomato;
}
